import { Price, TableList, TableListCell, TableListRow } from '@brainfinance/icash-component-library';
import * as React from 'react';

export type RepaymentScheduleItemType = {
  title: string;
  date: Date;
  amount: number;
};

type RepaymentsSchedulePropsType = {
  items: RepaymentScheduleItemType[];
};

function RepaymentsSchedule(props: RepaymentsSchedulePropsType) {
  return (
    <div className="flex justify-between max-w-[650px] mx-auto flex-wrap sm:flex-col">
      <TableList title="Repayments schedule">
        {props.items.map((item, index) => (
          <TableListRow key={index}>
            <TableListCell><p className='subtitle-medium'>{item.title}</p></TableListCell>
            <TableListCell>
              <p className='subtitle-medium'>
              {new Intl.DateTimeFormat('en-US', {
                weekday: 'short',
                month: 'short',
                day: '2-digit',
                year: 'numeric',
              }).format(item.date)}
              </p>
            </TableListCell>
            <TableListCell>
              <Price size="xsmall" amount={item.amount} />
            </TableListCell>
          </TableListRow>
        ))}
      </TableList>
    </div>
  );
}

export default RepaymentsSchedule;
