import { Button, CheckboxInput } from '@brainfinance/icash-component-library';
import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import { postMessageSender } from '../../../Helpers/PostMessage';
import { goToView, REVIEW_DOCUMENTS_PATH } from '../../../Helpers/Routes';
import { DashboardsContext } from '../../../Reducers/Dashboard';
import Summary, { SummaryMobile } from '../../Summary';
import CountdownContractExpire from '../../Utilities/CountdownContractExpire';
import RepaymentsSchedule from '../../Utilities/RepaymentsSchedule';
import './style.css';
import backMenu from '../../../images/chevron-left.svg';
import info from '../../../images/info.svg';
import ResizeObserver from 'resize-observer-polyfill';

function LoanAgreementReady() {
  const navigate = useNavigate();
  const [agreementAccepted, setAgreementAccepted] = React.useState<boolean>(false);
  const { state } = React.useContext(DashboardsContext);
  const [waitingContractFetch, setWaitingContractFetch] = React.useState(false);
  const iframeRef = React.useRef<HTMLDivElement | null>(null);

  React.useEffect(() => {
    if (!iframeRef.current) return;
    const resizeObserver = new ResizeObserver(event => {
      event.forEach(item => {
        const height = item.contentRect.height + 44 + 44;

        postMessageSender({
          status: 'size',
          data: {
            height: height,
          },
        });
      });
    });

    resizeObserver.observe(iframeRef.current);
    return () => {
      resizeObserver.disconnect();
    }; // clean up
  }, [iframeRef]);

  const onAgreeChange = (value: boolean | undefined) => {
    if (value !== undefined) {
      setAgreementAccepted(value);
    }
  };

  const onGoNext = React.useCallback(() => {
    postMessageSender({
      status: 'marketing',
      payload: {
        status: 'track',
        data: {
          event: 'sign_contract_payment_plan_accepted',
        },
      },
    });

    goToView(navigate, REVIEW_DOCUMENTS_PATH);
  }, [navigate]);

  React.useEffect(() => {
    if (state.loanContract && waitingContractFetch) {
      onGoNext();
    }
  }, [state, waitingContractFetch, onGoNext])

  const onAcceptAndContinue = async () => {

    if (!state.loanContract) {
      setWaitingContractFetch(true);
      return;
    }
   
    onGoNext();
  };

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  React.useEffect(() => {
    postMessageSender({
      status: 'marketing',
      payload: {
        status: 'screen',
        data: {
          categoryName: 'Dashboard',
          pathName: 'sign_contract_payment_plan_loaded',
        },
      },
    });
  }, []);

  return (
    <div ref={iframeRef} className="">
      <h1 className="main-title-caption mb-[30px] sm:hidden">Congratulations! Your loan agreement is ready!</h1>
      <div className="hidden sm:sticky sm:top-0 bg-[#FFFFFF] sm:mb-[11px] sm:grid grid-cols-12 items-center py-[11px] z-[20]">
        <div className="col-span-1 p-[8px]" onClick={() => postMessageSender({ status: 'return' })}>
          <img alt="back" className="col-span-1" src={backMenu} rel="preload" />
        </div>
        <h2 className="title-caption !text-[18px] text-center col-span-10">
          Congrats! Your
          <br />
          agreement is ready!
        </h2>
        <div className="flex justify-end col-span-1 p-[8px]">
          <img
            alt="contact-us"
            src={info}
            rel="preload"
            onClick={() => postMessageSender({ status: 'contact-us', data: { message: 'Contact us mobile' } })}
          />
        </div>
      </div>
      <div className="flex flex-row sm:px-[8px]">
        <div id="content" className="w-[486px] sm:w-[100%] flex flex-col">
          <div>
            <p className="caption-light">
              Please take a moment to read and understand the repayment schedule of your loan agreement.
            </p>
          </div>
          <div id="right" className="hidden sm:block ml-[90px] sm:ml-[0px] sm:mt-[22px]">
            <SummaryMobile
              loanDetails={state.loanDetails ?? 0}
              repayments={state.repayments ?? 0}
              totalAmountOfPayments={state.totalAmountOfPayments ?? 0}
            />
          </div>
          <div className="mt-[22px]">
            <RepaymentsSchedule items={state.repaymentScheduleItems ?? []} />
          </div>
          <p className="caption-light mb-[16px]">
            If the repayment dates are incorrect, contact us at{' '}
            <a href='mailto:application@icash.ca' title='application@icash.ca' className="text-primary cursor-pointer">
              application@icash.ca
            </a>{' '}
            to request a modification before signing your loan agreement.
            <br />
            Once accepted, we won't be able to modify your repayment dates.
          </p>
          <CountdownContractExpire date={state.countdownContractExpireDate ?? new Date()} />
          <div className="contract-signature--loan-agreement-ready-divider mt-6"></div>
          <CheckboxInput value={agreementAccepted} onChange={onAgreeChange}>
            <p className="caption">
              I agree to the repayments on these dates and acknowledge that I won't be able to modify the repayment
              dates.
            </p>
          </CheckboxInput>
          <Button
            disabled={!agreementAccepted || waitingContractFetch}
            loading={waitingContractFetch}
            appearance="primary"
            size="large"
            className="w-[100%]"
            onClick={onAcceptAndContinue}
          >
            Accept and continue
          </Button>
        </div>
        <div id="right" className="ml-[90px] sm:ml-[0px] sm:hidden">
          <Summary
            loanDetails={state.loanDetails ?? 0}
            repayments={state.repayments ?? 0}
            totalAmountOfPayments={state.totalAmountOfPayments ?? 0}
          />
        </div>
      </div>
    </div>
  );
}

export default LoanAgreementReady;
