import { createContext, Dispatch, useReducer } from 'react';
import { DocumentItemType } from '../../Components/Utilities/DocumentsView';
import { RepaymentScheduleItemType } from '../../Components/Utilities/RepaymentsSchedule';

type RequestsStateType = {
  loanDetails?: number;
  repayments?: number;
  totalAmountOfPayments?: number;
  countdownContractExpireDate?: Date;
  repaymentScheduleItems?: RepaymentScheduleItemType[];
  loanId?: number;
  token?: string;
  loanContract?: DocumentItemType;
  padContract?: DocumentItemType;
  firstName?: string;
  lastName?: string;
  isReloan?: boolean;
  nbLoans?: number;
  referralAmbassadorName?: string;
  provinceId?: number;
};

type RequestsActionType =
  | {
      type: 'setData';
      payload?: {
        loanDetails?: number;
        repayments?: number;
        totalAmountOfPayments?: number;
        countdownContractExpireDate?: Date;
        repaymentScheduleItems?: RepaymentScheduleItemType[];
        loanId?: number;
      };
    }
  | {
      type: 'setLoanContract';
      payload?: {
        file?: DocumentItemType;
      };
    }
  | {
      type: 'setPADContract';
      payload?: {
        file?: DocumentItemType;
      };
    }
  | {
      type: 'setToken';
      payload?: {
        token?: string;
      };
    }
  | {
      type: 'setUser';
      payload?: {
        firstName?: string;
        lastName?: string;
        isReloan?: boolean;
        nbLoans?: number;
        referralAmbassadorName?: string;
        provinceId?: number;
      };
    };

const dashboardsReducer = (state: RequestsStateType, action: RequestsActionType) => {
  switch (action.type) {
    case 'setData':
      return {
        ...state,
        loanDetails: action.payload?.loanDetails,
        repayments: action.payload?.repayments,
        totalAmountOfPayments: action.payload?.totalAmountOfPayments,
        countdownContractExpireDate: action.payload?.countdownContractExpireDate,
        repaymentScheduleItems: action.payload?.repaymentScheduleItems,
        loanId: action.payload?.loanId,
      };
    case 'setLoanContract':
      return { ...state, loanContract: action.payload?.file };
    case 'setPADContract':
      return { ...state, padContract: action.payload?.file };
    case 'setToken':
      return { ...state, token: action?.payload?.token };
    case 'setUser':
      return {
        ...state,
        firstName: action?.payload?.firstName,
        lastName: action?.payload?.lastName,
        isReloan: action?.payload?.isReloan,
        nbLoans: action?.payload?.nbLoans,
        referralAmbassadorName: action?.payload?.referralAmbassadorName,
        provinceId: action?.payload?.provinceId,
      };
    default:
      return state;
  }
};

export const useDashboardsReducer = () => useReducer(dashboardsReducer, {}, () => ({}));

export const DashboardsContext = createContext<{
  state: RequestsStateType;
  dispatch: Dispatch<RequestsActionType>;
}>({
  state: {},
  dispatch: _action => {},
});
