import * as React from 'react';
import { Route, Routes } from 'react-router-dom';
import ReviewDocuments from '../InstantLoan/ReviewDocuments';
import LoanAgreementReady from '../InstantLoan/LoanAgreementReady';
import { LOAN_AGREEMENT_READY_PATH, REVIEW_DOCUMENTS_PATH } from '../../Helpers/Routes';
import { DashboardsContext, useDashboardsReducer } from '../../Reducers/Dashboard';
import { Loader } from '../Utilities/Loader';
import { getContracts, getDashboard, getUserInfo } from '../../Helpers/Requests';
import { RepaymentScheduleItemType } from '../Utilities/RepaymentsSchedule';
import { postMessageSender } from '../../Helpers/PostMessage';
import { useStackDriver } from '../../Helpers/StackDriver';
import { useLogRocket } from '../../Helpers/LogRocket';

function App() {
  const [loadingDashboard, setLoadingDashboard] = React.useState<boolean>(true);
  const [loadingUserInfo, setLoadingUserInfo] = React.useState<boolean>(true);
  const [requestsState, requestsDispatch] = useDashboardsReducer();
  const { updateUser } = useStackDriver();
  const logRocket = useLogRocket();

  const fetchUserData = async (token: string) => {
    const data = await getUserInfo(token);

    if (data) {
      updateUser(data.email);
      requestsDispatch({
        type: 'setUser',
        payload: {
          firstName: data.firstName,
          lastName: data.lastName,
          isReloan: data.isReloan,
          nbLoans: data.nbLoans,
          referralAmbassadorName: data.referralAmbassadorName,
          provinceId: data.provinceId,
        },
      });
    }
    setLoadingUserInfo(false);
  };

  const fetchDashboardData = async (token: string) => {
    const data = await getDashboard(token);

    if (data) {
      if (data.status === 'pendingSignature') {
        let totalAmountOfPayments: number = 0;

        const repaymentScheduleItems: RepaymentScheduleItemType[] = [];
        const paymentPlan = data.data?.paymentPlan ?? [];
        for (let i = 0; i < paymentPlan.length; i++) {
          const plan = paymentPlan[i];
          totalAmountOfPayments += plan.amount;
          repaymentScheduleItems.push({
            amount: plan.amount,
            date: new Date(plan.dueDate.replace(/-/g, '/')),
            title: `${ i+1 }${ i>1 ? 'rd' : i ? 'nd' : 'st'} payment`,
          });
        }

        requestsDispatch({
          type: 'setData',
          payload: {
            countdownContractExpireDate: new Date(data.data?.contractExpirationDate ?? ''),
            repayments: data.data?.terms,
            loanDetails: data.data?.amount,
            totalAmountOfPayments: totalAmountOfPayments,
            repaymentScheduleItems: repaymentScheduleItems,
            loanId: data.data?.id,
          },
        });

        fetchGetContracts(token, data.data?.id);

        setLoadingDashboard(false);
      } else {
        postMessageSender({
          status: 'error',
          data: {
            code: 500,
            message: 'The account is not in pending signature.',
          },
        });
      }
    }
  };

  const fetchGetContracts = async (token: string, loanId?: number) => {
    const data = await getContracts(loanId, token);

    if (data) {
      requestsDispatch({
        type: 'setLoanContract',
        payload: {
          file: {
            content: data.contract,
            title: 'Contract',
          },
        },
      });

      requestsDispatch({
        type: 'setPADContract',
        payload: {
          file: {
            content: data.pad,
            title: 'PAD',
          },
        },
      });
    }
  };

  React.useEffect(() => {
    logRocket.init();
    
    const authEventListener = (e: MessageEvent) => {
      try {
        let message = typeof e.data === 'string' ? JSON.parse(e.data) : e.data;

        if (message && message.token) {
          requestsDispatch({ type: 'setToken', payload: { token: message.token } });
          fetchDashboardData(message.token);
          fetchUserData(message.token);
        }
      } catch (error) {}
    };
    window.addEventListener('message', authEventListener);

    return () => {
      window.removeEventListener('message', authEventListener);
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  React.useEffect(() => {
    console.log('sending ready event.');
    postMessageSender({
      status: 'ready',
      data: {
        message: 'Component Ready.',
      },
    });

    return () => {};
  }, []);

  return (
    <DashboardsContext.Provider value={{ state: requestsState, dispatch: requestsDispatch }}>
      <Loader loading={loadingDashboard || loadingUserInfo}>
        {/* loadingDashboard || loadingContracts || loadingUserInfo  */}
        <Routes>
          <Route path={LOAN_AGREEMENT_READY_PATH} element={<LoanAgreementReady />} />
          <Route path={REVIEW_DOCUMENTS_PATH} element={<ReviewDocuments />} />
        </Routes>
      </Loader>
    </DashboardsContext.Provider>
  );
}

export default App;
